import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature, ZoomControl } from 'react-mapbox-gl';
import ConfiguredMapboxGl, { MapboxStyle } from '../../helpers/ConfiguredMapboxGl';

class SingleLocationMap extends React.Component {
  constructor(props) {
    super(props);
  }

  coordinates() {
    const { latitude, longitude } = this.props;
    return [longitude, latitude];
  }

  renderMarker() {
    const { showMarker } = this.props;

    if (showMarker) {
      return (
        <Feature coordinates={this.coordinates()} />
      );
    }

    return null;
  }

  render() {
    const { zoom } = this.props;

    return (
      <ConfiguredMapboxGl
        style={MapboxStyle}
        zoom={[zoom]}
        containerStyle={{ height: '100%', width: '100%' }}
        movingMethod="easeTo"
        center={this.coordinates()}
      >
        <ZoomControl position="top-left" />
        <Layer
          id="item-layer"
          type="symbol"
          layout={{ 'icon-image': 'marker_15', 'icon-size': 1.5 }}
        >
          {this.renderMarker()}
        </Layer>
      </ConfiguredMapboxGl>
    );
  }
}

SingleLocationMap.defaultProps = {
  showMarker: true,
  zoom: 9,
};

SingleLocationMap.propTypes = {
  showMarker: PropTypes.bool.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  zoom: PropTypes.number,
};

export default SingleLocationMap;
